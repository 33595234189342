/* Styles and functionality for new redesigned tabs component */

.tabsComponent {
    width: 100%;
    // Tabs Container
    &__tabs {
        position: relative;
        display: flex;
    }
    // Radio input
    &__control {
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:checked,
        &--selected {
            + .tabsComponent__label {
                /*border-color: $brand-secondary-light;
                color: $brand-secondary-light;
                background: $white;
                */
                color: $white;
                background-color: $brand-secondary-light;
                border-color: $brand-secondary-light;
                cursor: default;

                &:hover,
                &:focus {
                    /*color: $brand-secondary-light;
                    background: $white;*/
                    color: $white;
                    background-color: $brand-secondary-light;
                    cursor: default;
                }

                &:after {
                    background-color: $white;
                    z-index: 2;
                    //left: -4px;
                }
            }
        }
    }
    // Tabs text label
    &__label {
        flex: 0 1 auto;
        cursor: pointer;
        box-sizing: border-box;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 50px;
        transition: color 0.2s ease;
        width: 100%;
        text-transform: uppercase;
        /*margin: 0 2px 4px 2px;*/
        margin: 0;
        position: relative;
        font-size: em(12);
        padding: 0 5px;
        font-family: $ff--bold;
        transition: $default-animation;
        /*border-top: solid 1px $brand-secondary-light;
        color: $white;
        background-color: $brand-secondary-light;*/
        border-top: solid 1px $brand-greyscale-light;
        color: $brand-secondary-light;
        background-color: $white;
        font-weight: bold;

        @include breakpoint($bp-small) {
            font-size: em(15);
        }

        &:hover,
        &:focus {
            /*background: $brand-secondary-medium;
            border-color: $brand-secondary-medium;*/
            background: $brand-greyscale-light;
            border-color: $brand-greyscale-light;
        }

        &:after {
            content: "";
            height: 1px;
            width: calc(100% + 4px);
            background-color: $brand-greyscale-medium;
            position: absolute;
            top: 48px;
            left: 0;
        }

        &:first-of-type {
            margin-left: 0;
            border-left: 1px solid $brand-greyscale-light;
        }

        &:last-of-type {
            margin-right: 0;
            border-right: 1px solid $brand-greyscale-light;

            &:after {
                width: 100%;
            }
        }

        &--inactive {
            color: $brand-greyscale-medium;
            background-color: $brand-greyscale-light;
            border-top: 1px solid $brand-greyscale-light;
            cursor: default;

            &:hover,
            &:focus {
                cursor: default;
                color: $brand-greyscale-medium;
                background-color: $brand-greyscale-light;
                border-top: 1px solid $brand-greyscale-light;
            }
        }
    }
    // Tab content
    &__content {
        position: relative;
        width: 100%;
        display: none;
        margin-bottom: $default-spacing;

        @media (min-width: 499px) {
            padding: $default-spacing;
        }

        @media (min-width: 500px) {
            border-bottom: 1px solid $brand-greyscale-light;
            border-top: 1px solid $brand-greyscale-light;
            border-left: 1px solid $brand-greyscale-light;
            border-right: 1px solid $brand-greyscale-light;
        }

        &--selected {
            display: block;
        }
    }

    &__showDropdown {
        @media (min-width: 767px) {
            display: none;
        }
    }

    &__hideTabs {
        @media (max-width: 767px) {
            display: none;
        }
    }

    &__noPadding {
        @media (max-width: 499px) {
            padding: 0;
        }
    }
}
