﻿
// Shades 
$white: #FFFFFF; // Used for backgrounds and text
$lightest-grey: #EDEDED; // Used for backgrounds
$light-grey: #D8D8D8; // Used for borders
$normal-grey: #7F7F7F; // Used for light text
$mid-grey: #5A5A5A; // Used for medium text
$dark-grey: #3A3637; // Used for dark text
$black: #000000; // Used for text
//$navy: #2f4a9d; //used for menu bar, table header
$skyline-blue: #2eabed;
$light-skyline-blue: lighten($skyline-blue, 40%); //used for table alt row
$dark-skyline-blue: darken($skyline-blue, 10%);
$skyline-grey: #576567;
$light-skyline-grey: lighten($skyline-grey, 55%); //used for table alt row
$lighter-skyline-grey: lighten($skyline-grey, 57%); //used for table alt row
$extralight-skyline-grey: lighten($skyline-grey, 60%); //used for table alt row
$nav-skyline-grey: lighten($skyline-grey, 20%); //used for nav menu
$firstresponse-green: #73ff08;

$light-firstresponse-green: lighten($firstresponse-green, 40%);
$dark-firstresponse-green: darken($firstresponse-green, 20%);
$darker-firstresponse-green: darken($firstresponse-green, 25%);

//$light-blue: #ccdcff; //used for table alt row
$brand-primary: #000066;
/*$brand-secondary-light: $navy;
$brand-secondary-medium: darken($navy, 10%);
$brand-secondary-dark: darken($navy, 20%);*/
$brand-secondary-light: $skyline-blue;
$brand-secondary-medium: darken($skyline-blue, 10%);
$brand-secondary-dark: darken($skyline-blue, 20%);
$brand-greyscale-light: #F2F2F2;
$brand-greyscale-medium: #E0E0E0;
$brand-greyscale-normal: #666666;
$brand-greyscale-dark: #333333;
$highlight-yellow: #ffff99;

$font-size: 14px;
$mobile-font-size: 16px;    //min so mobile won't zoom the screen in

//variable for label width when inline
$label-width: 165px;
$label-width-small: 100px;
$label-width-medium: 150px;
$label-width-large: 220px;

// Breakpoint variables
$bp-tiny: 370px;
$bp-tiny-2: 410px;
$bp-small: 480px;
$bp-med: 540px;
$bp-med-1: 650px;
$bp-med-2: 680px; // main mobile breakpoint
$bp-med-3: 740px;
$bp-large: 850px;
$bp-large-2: 1010px;
$bp-large-3: 1250px; // main tablet breakpoint
$bp-xl: 1400px;

// Set up z-indexes 
$z-index-notifications: 80;
$z-index-fullscreen: 70;
$z-index-modal: 60;
$z-index-modalOverlay: 50;
$z-index-mobileFixed: 40;
$z-index-header: 30;
$z-index-offcanvas: 20;
$z-index-contentPop: 10;
$z-index-contentOver: 2;
$z-index-content: 1;
$z-index-contentUnder: 0;
$z-index-hideBehind: -1;

//Font Families
$ff--bold: 'Muli', sans-serif;

//Transistion Variables
$animate--fastest: .06s;
$animate--racing: .12s;
$animate--fast: .25s;
$animate--slow: .45s;
$animate--snail: 1s;
$default-animation: all 200ms ease-in-out;

$default-spacing: 20px;