﻿.version {
    font-size: 10px;
}

.jobs {
    &__splitScreen {
        width: 80%;
        display: inline-flex;
    }

    &__splitScreen2 {
        display: inline-flex;
    }
}

.jobdetails {
    &__copy {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
    }

    &__heading {
        width: $label-width;
    }

    &__importpdf {
        margin-bottom: 0.5rem;
    }
}



.makeSafeJob {
    font-weight: bold;
    color: darkorange;
}

.reportOnlyJob {
    font-weight: bold;
    color: brown;
}

.notes {
    &__size {
        height: 150px;
        width: 100%;
        max-width: none;
    }

    &__button-position {
        @media (min-width: 450px) {
            margin-left: $label-width;
        }
    }

    &__check--width {
        width: 120px;
    }

    &__label--system-size {
        font-size: 13px;
        width: 200px;
    }
}

.files {
    &__view-position {
        @media (min-width: 420px) {
            margin-left: $label-width;
        }
    }

    &__button-position {
        @media (min-width: 401px) {
            margin-left: $label-width;
        }
    }
}

.fileSize {
    font-size: 20px;
}


.list-close--nowrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    line-height: 30px;
}

.checkboxlist--margin {
    margin-right: 20px;
}

//Fix Modal classes for Confirm Popup
.fade:not(.show) {
    opacity: 1;
}

.modal-backdrop.fade {
    opacity: 0.7;
}

.modal-dialog {
    top: 50px;
}

.modal-header {
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
}
//End Fix Modal

.adminlists {
    &__item {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 1.4em;
    }
}

.adminProducts-size {
    max-width: 300px;
}

.job {
    &__noteSize {
        height: 100px;
    }
    &__editMargin {
        margin-right: 30px;
    }
}

.file__photoColour {
    color: $dark-firstresponse-green;
}

.file__photo--border {
    border: 1px solid #ccc;
    padding: 10px 10px 0;
    margin-bottom: 10px;

    @media (min-width: 768px) {
        margin-bottom: 0;
    }
}

.estimate {
    &__alignTotals {
        @media (min-width: 768px) {
            justify-content: flex-end;
        }

        width: 100%;
    }

    &__qtyWidth {
        width: 100px;
    }

    &__tdWidth {
        width: 130px;
    }

    &__scopeWidth {
        min-width: 150px;
    }

    &__maxSupplierWidth {
        max-width: 150px;
    }

    &__maxPcPsWidth {
        max-width: 60px;
    }

    &__backButton {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        align-items: center;
    }

    &__photoSize {
        max-width: 470px;
        max-height: 470px;
    }

    &__report--size {
        width: 100%;
        margin: 10px 0;

        @media (min-width: 414px) {
            max-width: 175px;
            margin: 0 0 0 10px;
        }
    }

    &__consultScope {
        width: 100%;
    }
}

.estimate-consultantnotes--size {
    width: 300px;
}

.estimate-mobile-totals {
    width: 140px;
    display: inline-block;
}

.estimate-import--btnPosition {
    display: flex;
    justify-content: flex-end;
}

.input-group, .input {
    .estimate__totalsWidth {
        @media (min-width: 414px) {
            max-width: 200px;
        }
    }

    .jobdets__company--width {
        max-width: 330px;
    }
}

.input-group, .label {
    .labelLarge {
        width: 220px;
    }

    .labelXLarge {
        width: 300px;
    }
}

.input-group, .select {
    .supplier-importbatch--width {
        max-width: 200px;
    }

    .supplier-import-posearch--width {
        max-width: 280px;
    }
}

.login__position {
    display: flex;
    justify-content: center;
}

.login__buttonmargin {
    margin-top: 30px;
}

.notesModalSize {
    width: 100%;
}
//used for items that are listed under a dropdown - delete icon
.alignDeleteIcon {
    text-align: center;
    width: 50px;
    font-size: 20px;
    line-height: 30px;
}

.jobCostings {
    &__size {
        width: 150px;
    }

    &__tabSize {
        width: 200px;
    }

    &__label--date-size {
        font-size: 13px;
        width: 160px;
    }
}

.jobpo {
    &__addNew {
        @media (min-width: 475px) {
            display: flex;
            justify-content: space-between;
        }

        margin-bottom: 10px;
    }

    &__textarea {
        align-items: flex-start;
    }

    &__textHeight {
        height: 142px;
    }

    &__exeedReasonSize {
        margin-left: 20px;
        width: 500px;
    }

    &__backButton {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}

.jobInvoice {
    &__approve {
        display: flex;
        padding: 4px 10px;
        border-radius: 4px;
        background-color: #46a100;
        border-color: #46a100;
        color: #fff;
        border-width: 0;
        font-size: 1rem;
    }

    &__multi-ins--colWidth {
        width: 130px;
    }

    &__multi-ins--amtWidth {
        width: 110px;
    }
}

.jobSchedule {
    &__dateLabelSize {
        margin-right: 30px;
    }

    &__alignRight {
        justify-content: flex-end;
    }

    &__add {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }
}

.estimateShortcut {
    &--item {
        width: 100%;
    }
}

.shortcutOption {
    .shortcutRow:nth-child(even) {
        background-color: $light-skyline-blue;
    }

    .shortcutRow:nth-child(odd) {
        background-color: $light-skyline-grey;
    }
}

.supplier-import--position {
    right: 120px;
}

.supplier-import {
    &__date--size {
        width: 140px;
    }
    &__text--size {
        width: 100px;
    }
    &__font--size {
        font-size: 12px;
    }
    &__icon--size {
        font-size: 20px;
    }
}

.supplier-invoice--icon {
    font-size: 30px;
    margin-left: 10px;
    margin-bottom: 5px;
}

.supplier-invoice {
    &__search--position {
        margin-bottom: 5px;
    }
}

.supplier-invoice--duplicate {
    background-color: #ffcccc;
}

.tableColours tbody tr:nth-child(odd).supplier-invoice--duplicate {
    background-color: #ffcccc;
}

.tableColours tbody tr:nth-child(even).supplier-invoice--duplicate {
    background-color: #ffcccc;
}

.supplier-certificate-size {
    width: 100%;
    height: 300px;
    max-width: 500px;
}

//sizing and margins for 3 buttons within a input group parent div
.threebuttons {
    &__btn2--position {
        margin-top: 10px;

        @media (min-width: 370px) {
            margin-top: 0;
            margin-left: 10px;
        }

        @media (min-width: 768px) {
            margin-top: 10px;
            margin-left: 0;
        }

        @media (min-width: 936px) {
            margin-top: 0;
            margin-left: 10px;
        }
    }

    &__btn3--position {
        margin-top: 10px;

        @media (min-width: 1256px) {
            margin-top: 0;
            margin-left: 10px;
        }
    }
}

.fourbuttons {
    &__btn2--position {
        margin-top: 10px;

        @media (min-width: 370px) {
            margin-top: 0;
            margin-left: 10px;
        }

        @media (min-width: 768px) {
            margin-top: 10px;
            margin-left: 0;
        }

        @media (min-width: 870px) {
            margin-top: 0;
            margin-left: 10px;
        }
    }

    &__btn3--position {
        margin-top: 10px;

        @media (min-width: 525px) {
            margin-top: 0;
            margin-left: 10px;
        }

        @media (min-width: 768px) {
            margin-top: 10px;
            margin-left: 0;
        }

        @media (min-width: 1175px) {
            margin-top: 0;
            margin-left: 10px;
        }
    }

    &__btn4--position {
        margin-top: 10px;

        @media (min-width: 370px) {
            margin-left: 10px;
        }

        @media (min-width: 525px) {
            margin-left: 0;
        }

        @media (min-width: 870px) {
            margin-left: 10px;
        }

        @media (min-width: 1175px) {
            margin-left: 0;
        }

        @media (min-width: 1490px) {
            margin-top: 0;
            margin-left: 10px;
        }
    }
}

.invoiceSelectionMargin {
    margin-right: 40px;
}

.invoice__fileSize {
    width: 100%;
    height: 600px;
}

.invoice {
    &__back--position {
        margin-left: 10px;
        margin-bottom: 10px;
    }
}

.certificate__fileSize {
    width: 100%;
    height: 300px;
}

.backButton--right {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    align-items: center;
}

.client {
    &__xeroTableSize {
        max-width: 615px;
    }
}

.photoImport {
    &--size {
        width: 100%;
        height: 200px;
    }

    &--comment-size {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &--height {
        height: 200px;
    }

    &__delete {
        float: right;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1;
        color: red;
        padding: 0;
        background-color: transparent;
        border: 0;
    }

    &__deleteParent--position {
        position: relative;
    }

    &__delete--position {
        position: absolute;
        top: -9px;
        right: -9px;
    }

    &__single-delete--position {
        position: absolute;
        top: -10px;
        right: -10px;
    }

    &__single-photo--width {
        width: 100%;
        max-height: 500px;
    }
}

.photoReport-comment--size {
    height: 100px;
    width: 100%;
}
//stop the stimulsoft report viewer going over the nav bars
.stiJsViewerMainPanel {
    z-index: 0;
}

.stiJsViewerParentMenu {
    left: 331px;
    top: 81px;
}

.assessment {
    &__textSize {
        height: 250px;
    }

    &__photo--width {
        max-width: 780px;
    }

    &__photoSize {
        @media (min-width: 860px) {
            margin-left: $label-width-large;
        }

        margin-bottom: 10px;
        max-height: 250px;
        width: 100%;

        @media (min-width: 414px) {
            width: unset;
        }

        max-width: 450px;
    }
}

.ongoinghire-other--size {
    width: 250px;
}

.user {
    &__reset-position {
        width: 130px;

        @media (min-width: 480px) {
            margin-left: $label-width;
        }
    }

    &__permission-group {
        margin-left: calc($label-width - 20px);
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 24px;
        text-decoration: underline;
    }
}

.signature {
    &__border {
        border: 1px solid gray;
    }
}

.adminlists__item .nav-link {
    color: $dark-skyline-blue;
    margin: 10px;
}

.adminlists__item .nav-link:focus,
.adminlists__item .nav-link:hover {
    color: $skyline-blue;
}

.calendar-header--border {
    border: solid 1px $light-skyline-grey;
}

.calendar-td-width {
    width: 14.25%;
    border: solid 1px $light-skyline-grey;
    position: relative
}

//calculate height based on number of rows returned
.calendar-td-height-4 {
    height: 25%
}

.calendar-td-height-5 {
    height: 20%
}

.calendar-td-height-6 {
    height: 16.6%
}

.calendar-table-height {
    height: calc(100vh - 130px);
}

.calendar-day-height {
    height: 25px;
}

.calendar-day--pos {
    position: absolute;
    top: 2px;
    left: 2px;
    padding: 5px 10px;
    height: 30px;
    width: 30px;
}

.calendar-today {
    background-color: $dark-skyline-blue;
    color: white;
    border-radius: 15px;
    display: flex;
    justify-content: center;
}

.calendar--icon {
    font-size: 25px;
}

.calendar-appointment-colours {
    div:nth-of-type(even) {
        background-color: $light-firstresponse-green;
        color: $black;
    }

    div:nth-of-type(odd) {
        background-color: $lighter-skyline-grey;
        color: $black;
    }
}

.calendar-appt-scroll {
    overflow: auto;
    height: calc(100% - 25px);
}

.calendar-appt-shape {
    border-radius: 10px;
    padding: 5px;
    font-size: 12px;
}