﻿.calendar-addnew-pos {
    position: absolute;
    top: 10px;
    right: 10px;
}
html {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    margin: 0;
    font-size: $font-size;
    font-weight: 400;
    overflow-x: hidden;

    select, input, textarea {
        font-size: $mobile-font-size;

        @media (min-width: 768px) {
            font-size: $font-size;
        }
    }
}

*, *:before, *:after {
    box-sizing: border-box;
}

body {
    //@include font("regular");
    background: $white;
    color: $black;
    transition: background .2s ease-in-out;
    width: 100%;
    margin: 0;

    @media (min-width: 768px) {
        margin-top: 50px; //allow space for the top menu
    }

    overflow-x: hidden;

    &.filters-open {
        overflow: hidden;
        height: 100%;

        @include breakpoint($bp-large-2) {
            overflow: auto;
            height: auto;
        }
    }
}

@media (min-width: 768px) {
    .container {
        margin-left: 200px;
        width: calc(100% - 200px);
        max-width: none;
    }
}

.fullContainer {
    margin-left: 30px;
    margin-right: 30px;
    width: 100%;
}

.mobileContainer {
    top: 80px;
    bottom: 70px;
    padding-right: 10px;
    padding-left: 5px;
    padding-bottom: 10px;
    position: fixed;
    overflow: auto;
    width: calc(100% - 20px);
    z-index: -1;
}

.overflowAuto {
    overflow: auto;
}

.hidden {
    display: none;
}

.block {
    display: block;
}

.logo {
    height: 40px;

    &__small {
        margin: 5px;
    }
}

.pac-container {
    z-index: 2000;
}

@media (min-width: 768px) {
    .logo__small {
        display: none;
    }
}

.marginTop10 {
    margin-top: 10px;
}

.marginTop70 {
    margin-top: 70px;
}

.marginLeft10 {
    margin-left: 10px;
}

.marginLeft15 {
    @media (min-width: 500px) {
        margin-left: 15px;
    }
}

.marginLeft40 {
    margin-left: 40px;
}

.marginRight10 {
    margin-right: 10px;
}

.marginRight15 {
    @media (min-width: 500px) {
        margin-right: 15px;
    }
}

.marginRight20 {
    margin-right: 20px;
}

.marginBottom5 {
    margin-bottom: 5px;
}

.marginBottom10 {
    margin-bottom: 10px;
}

.marginBottom15 {
    margin-bottom: 15px;
}

.marginBottom50 {
    margin-bottom: 50px;
}

.marginBottom70 {
    margin-bottom: 70px;
}

.paddingLeft10 {
    padding-left: 10px;
}

.paddingBottom10 {
    padding-bottom: 10px;
}

.photos-multiple--even {
    @media (min-width: 768px) {
        margin-left: 10px;
    }
}

.photos-multiple--odd {
    @media (min-width: 768px) {
        margin-right: 10px;
    }
}


.marginRight10Mobile {
    @media (min-width: 415px) {
        margin-right: 10px;
    }
}

.marginTop20 {
    margin-top: 20px;
}

.marginLeft10Mobile {
    @media (min-width: 415px) {
        margin-left: 10px;
    }
}

.marginLeftLabel {
    margin-left: $label-width;
}

.marginLeft10LabelMobile-small {
    margin-left: $label-width-small;

    @media (min-width: 415px) {
        margin-left: 10px;
    }
}

.padding--none {
    padding: 0;
}

.positionrel {
    position: relative;
}

.textaligncenter {
    text-align: center;
}

.textalignright {
    text-align: right;
}

.textalignleft {
    text-align: left;
}

.labeltextalignright {
    display: inline-block;
    text-align: right;
}

.checkbox__Size {
    max-width: 15px !important;
}

.delete--tablecell {
    margin: auto;
    width: 100%;
    text-align: center;
}

.delete--icon {
    color: red;
    font-size: 20px;
    line-height: 32px;
}

.makeitflex {
    display: flex;
}

.makeitflexcenter {
    display: flex;
    align-items: center;
}

.makeitflexspacebetween {
    display: flex;
    justify-content: space-between;
}

.makeitverticalmiddle {
    vertical-align: middle;
}

.makeitred {
    color: red;
}

.makeitblack {
    color: black;
}

.makeitgreen {
    color: $darker-firstresponse-green;
}

.makeitskyblue {
    color: $skyline-blue;
}

.makeitbold {
    font-weight: bold;
}

.copy--icon {
    font-size: 20px;
    line-height: 32px;
}

.edit--icon {
    font-size: 20px;
    line-height: 32px;
}

.mainform-sm--margin {
    margin-bottom: 20px;
}

.form-margin {
    margin: 10px;
}

//used for showing or hiding objects for mobile view
.hideMobile {
    @media (max-width: 499px) {
        display: none;
    }
}

.showMobile {
    @media (min-width: 500px) {
        display: none;
    }
}

.showMobileToggle:not(.show) {
    @media (max-width: 499px) {
        display: none;
    }
}

.mobileTableFont {
    font-size: 13px;

    @media (min-width: 768px) {
        font-size: $font-size;
    }
}

.mobileFont {
    font-size: $mobile-font-size;
}

.mobile-margin {
    margin: 20px 0;
}

.mobileSplitScreen {
    width: 50%;
}

.mobileBackground {
    padding: 10px;
    background-color: $light-skyline-grey;
}

.mobileAltBackground {
    padding: 10px;
    background-color: $extralight-skyline-grey;
}

.mobileMenuSize {
    font-size: 35px;
}

.mobile-input {
    width: 100%;
}

.mobile-icon {
    color: $skyline-blue;
    text-align: center;
}

a.mobile-icon, a.mobile-icon:link, a.mobile-icon:visited, a.mobile-icon:hover, a.mobile-icon:focus, a.mobile-icon:active {
    color: $skyline-blue;
    text-decoration: none;
}

.mobile-icon-green {
    color: $dark-firstresponse-green;
    text-align: center;
}

a.mobile-icon-green, a.mobile-icon-green:link, a.mobile-icon-green:visited, a.mobile-icon-green:hover, a.mobile-icon-green:focus, a.mobile-icon-green:active {
    color: $dark-firstresponse-green;
    text-decoration: none;
}

.mobile-icon-grey {
    color: $nav-skyline-grey;
    text-align: center;
}

a.mobile-icon-grey, a.mobile-icon-grey:link, a.mobile-icon-grey:visited, a.mobile-icon-grey:hover, a.mobile-icon-grey:focus, a.mobile-icon-grey:active {
    color: $nav-skyline-grey;
    text-decoration: none;
}

.mobile-icon-red {
    color: red;
    text-align: center;
}

a.mobile-icon-red, a.mobile-icon-red:link, a.mobile-icon-red:visited, a.mobile-icon-red:hover, a.mobile-icon-red:focus, a.mobile-icon-red:active {
    color: red;
    text-decoration: none;
}

.mobile-icon-white {
    color: white;
    text-align: center;
}

a.mobile-icon-white, a.mobile-icon-white:link, a.mobile-icon-white:visited, a.mobile-icon-white:hover, a.mobile-icon-white:focus, a.mobile-icon-white:active {
    color: white;
    text-decoration: none;
}

//end mobile only

//Splitting the screen into 2 columns
.columngroup {
    display: block;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;

    @media (min-width: 500px) {
        display: inline-flex;
    }
}

.columnleft {
    width: 100%;
    margin: 10px;

    @media (min-width: 500px) {
        width: 70%;
    }
}

.columnright {
    width: 100%;
    margin: 10px;

    @media (min-width: 500px) {
        width: 30%;
    }
}
//End Split Screen

//Inputs
.input-group {
    align-items: center;

    @media (min-width: 767px) {
        width: calc(100% - 30px); // should be contained by a form or something
    }

    @media (min-width: 500px) {
        display: inline-flex;
        flex-direction: row;
    }
    //.label,
    .input, .select {
        @media (min-width: 500px) {
            display: flex;
        }
    }

    .label {
        font-size: 13px;
        width: 100%;

        @media (min-width: 414px) {
            width: $label-width;
        }
    }

    .label-small {
        font-size: 13px;
        width: $label-width-small;
    }

    .label-medium {
        font-size: 13px;
        width: $label-width-medium;
    }

    .label-large {
        font-size: 13px;
        width: 100%;

        @media (min-width: 414px) {
            width: $label-width-large;
        }
    }

    .label-mobile--inline {
        width: 200px;
    }

    .label-mobile {
        font-size: 16px;
    }

    .input {
        flex-grow: 1;
        max-width: 450px; // arbitrary
        min-width: 80px;
        margin-bottom: 5px;
    }

    .select {
        flex-grow: 1;
        max-width: 450px; // arbitrary
        min-width: 80px;
        margin-bottom: 5px;
    }

    .checkbox {
        max-width: 15px;
    }
}

.input-group--nowrap {
    @extend .input-group;
    display: flex;
    flex-direction: column;
}

.input-group--mobile {
    margin: 15px 0;
}

.input-group--inline {
    display: flex;
    flex-direction: column;

    @media (min-width: 415px) {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
    }
}

input[type=text], input[type=password], select, textarea {
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}
//smaller padding for dates and numbers to cater for arrows
input[type=date], input[type=time], input[type=datetime-local], input[type=number] {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

select:disabled, input:disabled {
    background-color: #eee;
    opacity: 1
}

.label {
    padding: 12px 12px 12px 0;
    display: inline-block;
    color: $black;

    &__red {
        color: red;
    }

    &__green {
        color: $darker-firstresponse-green;
    }
}

.label--padding-small {
    @extend .label;
    padding: 5px 5px 5px 0;
}

@media (min-width: 768px) {
    //split screen into 2
    .input-group-parent {
        width: 50%;
        margin-top: 10px;
        display: inline-flex;
        flex-direction: column;
    }

    .input-group-parent--row {
        width: 50%;
        margin-top: 10px;
        display: inline-flex;
        flex-direction: row;
    }
    //split screen into 3
    .input-group-parent3 {
        width: 33%;
        margin-top: 10px;
        display: inline-flex;
        flex-direction: column;
    }

    .input-group-parent3--row {
        width: 33%;
        margin-top: 10px;
        display: inline-flex;
        flex-direction: row;
    }
}
//Tables and Sub Tables
.table--main {
    width: 100%;
    margin-bottom: 1rem;
    vertical-align: top;
    border-color: $light-skyline-grey;


    thead tr th {
        padding: 0.5rem 0.5rem;
    }

    tbody tr td {
        padding: 0.5rem 0.5rem;
    }
}


.table--smallHeaderSize {
    white-space: nowrap;
    width: 1px;
}

.table--cell--nowrap {
    white-space: nowrap;
}

.tableHeader--maxwidth {
    width: 200px;
}

.tableColours {
    thead tr {
        background-color: $skyline-blue;
        color: white;
    }
    /*tbody td {
        padding: 30px;
    }*/

    tbody tr:nth-child(even) {
        background-color: $light-skyline-blue;
        color: $black;
    }

    tbody tr:nth-child(odd) {
        background-color: $light-skyline-grey;
        color: $black;
    }
}

.subtableHeaderColour {
    background-color: $skyline-blue;
    color: white;
}

.subtableRowColour {
    background-color: $light-skyline-grey;
    color: $black;
}

.subtableSubRowColour {
    background-color: $light-skyline-blue;
    color: $black;
}

.subtable {
    margin-left: 30px;
    width: calc(100% - 30px); //remove the margin from the width
    &__large {
        margin-left: 5px;
    }
}

.row--hidden {
    display: none;
}

.rotate45deg {
    transform: rotate(45deg);
}

.table td {
    padding: 0.55rem;
}

.table {
    min-width: 500px;

    &__margin {
        margin-bottom: 20px;
    }

    &__auto {
        width: auto;
    }

    &__small {
        min-width: 0;
    }

    &__large {
        min-width: 700px;
    }

    &__extralarge {
        min-width: 800px;
    }

    &__select--size {
        height: 36px;
        padding: 5px;
        min-width: 100px;
    }

    &__text--align {
        vertical-align: middle !important;
    }

    &__center-text--align {
        text-align: center;
    }

    &__input--alignright {
        text-align: right;
    }

    &__input--min100 {
        min-width: 100px;
    }

    &__tdmin--sm {
        min-width: 100px;
    }
    &__tdmin--md {
        min-width: 130px;
    }
    &__tdmin-xlg {
        min-width: 200px;
    }

}

.tablerow {
    &__textmedium {
        min-width: 150px;
    }

    &__textsmall {
        min-width: 100px;
    }
}

.alignIconCenter {
    text-align: center;
    width: 100%;
}

.sortParent {
    position: relative;
}

.sortTop {
    position: absolute;
    right: 0;
    top: 0;
}

.sortBottom {
    position: absolute;
    right: 0;
    bottom: 0;
}

//End Tables
//Maps
.map {
    height: 500px;
    width: 500px;
}
//End Maps
//Buttons
.defaultbutton {
    width: 100px;
    height: 50px;

    @media (min-width: 500px) {
        width: 120px;

        &__multiple {
            width: 100px;
        }
    }

    @media (min-width: 767px) {
        width: 200px;
    }

    &__large {
        width: 200px;
    }

    &__medium {
        width: 146px;
    }

    &__small {
        width: 100px;
    }

    &__xsmall {
        width: 100px;
        height: 36px;
    }
}

.defaultbutton-prev {
    @extend .defaultbutton;
    margin-left: 0;
    /*Screens split in two vertically*/
    &__split {
        @media (min-width: 450px) {
            margin-left: $label-width;
        }

        @media (min-width: 767px) {
            margin-left: 0;
        }

        @media (min-width: 1000px) {
            margin-left: $label-width;
        }
    }

    &__split3 {
        @media (min-width: 350px) {
            margin-left: $label-width-small;
        }

        @media (min-width: 767px) {
            margin-left: 0;
        }

        @media (min-width: 1143px) {
            margin-left: $label-width-small;
        }
    }
}

.defaultbutton-next {
    @extend .defaultbutton;
    margin-left: 15px;
}

.defaultbutton-label-large {
    @media (min-width: 414px) {
        margin-left: $label-width-large;
    }
}

.defaultbutton-label {
    @media (min-width: 414px) {
        margin-left: $label-width;
    }
}

.defaultbutton__container--right {
    display: flex;
    justify-content: flex-end;
}

.defaultbutton__container--left {
    display: flex;
    justify-content: flex-start;
}

.logout-button {
    display: flex;
    padding: 7px 12px;
    border-radius: 4px;
    background-color: $dark-firstresponse-green;
    border-color: $dark-firstresponse-green;
    color: #fff;
}

.mobile-button {
    width: 100%;
    margin: 20px 0;
    padding: 12px;
    border-radius: 4px;
    color: #fff;

    &_green {
        background-color: $dark-firstresponse-green;
        border-color: $dark-firstresponse-green;
    }

    &_grey {
        background-color: $nav-skyline-grey;
        border-color: $nav-skyline-grey;
    }
}

.logout-button:hover {
    color: #fff;
    text-decoration: none;
    background-color: $darker-firstresponse-green;
}

.popup-button--yes {
    @extend .logout-button;
}

.popup-button--no {
    @extend .logout-button;
    background-color: $skyline-grey;
    border-color: $skyline-grey;
}

.popup-button--no:hover {
    color: #fff;
    text-decoration: none;
    background-color: $nav-skyline-grey;
}

.pagingrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.pagingbutton--note {
    height: 50px;
    margin: 0 10px;
    display: flex;
    align-items: center;
}

.pagingbutton {
    width: 50px;
    height: 50px;
    border-radius: 5px;
}
//End Buttons
//Errors
.errors {
    color: red;
    font-size: 12px;
    display: block;
    padding-top: 0;
    font-weight: bold;

    &__leftmargin {
        @media (min-width: 940px) {
            margin-left: $label-width;
        }
    }

    &__leftmargin--large {
        @media (min-width: 940px) {
            margin-left: $label-width-large;
        }
    }
}

.warning {
    color: darkorange;
}
//End Errors
//Tooltips
.tooltipitem {
    position: relative;
    display: inline-block;
}

.tooltipitem .tooltipitemtext {
    visibility: hidden;
    width: 150px;
    background-color: $light-grey;
    color: $dark-grey;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: -5px;

    &__left {
        right: 105%;
    }

    &__right {
        left: 105%;
    }
}

.tooltipitem:hover .tooltipitemtext {
    visibility: visible;
}
//End Tooltips
//Modal
.modal {
    &__header--colour {
        background-color: $skyline-blue;
        color: white;
    }

    &__close--colour {
        color: white;
        background-color: initial;
        border: none;
        font-size: 1.5rem;
    }

    &__close--colour:hover {
        color: white;
    }
}
//End Modal

.hr--no-margin {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-top-color: grey;
}