﻿.top-nav {
    height: 50px;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    align-items: center;
    padding: 6px;
    justify-content: space-between;
    background-color: #f2f2f2;
    z-index: 1;

    .fontsize {
        font-size: 1.2em;
    }

    @media (min-width: 767px) {
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
    }
}

.top-nav--mobile {
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 1;
}

.top-nav--fontsize {
    font-size: 1.4em;
}

.bottom-nav {
    position: fixed;
    height: 70px;
    padding: 10px 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $skyline-blue;
    display: flex;
    justify-content: space-between;
    z-index: 1;
}

.bottom-edit-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $lightest-grey;
    align-items: center;
    padding: 10px;
}

@media (min-width: 768px) {
    .navbar-hide {
        display: none;
    }
    .navbar {
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        z-index: 1;
        width: 200px;
        height: 100%;
        align-items: flex-start;
        padding: 0.5rem;
    }
    .navbar-expand-md .navbar-nav {
        flex-direction: column;
    }
}

.navbar-nav .active, .active:hover {
    background-color: $skyline-blue;
    color:white;
}

.navbar-nav {
    width: 100%;
    border-radius: 4px;
    font-size: 15px;
}

.navbar-nav a:hover {
    background-color: $nav-skyline-grey;
    color: white;
    border-radius: 4px;
}

.navbar-light .navbar-nav .nav-link .active {
    color: white;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: white;
}

.navbar-nav .nav-link {
    padding-left: 10px;
}

.navbar-mobile-margin {
    @media (max-width: 499px) {
        margin-top: 70px;
    }
}